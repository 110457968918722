import React from "react";

//Components
import Proyectos from "./Proyectos";


const ProyectosP = () => {
    return (
        <div style={{position:'relative', zIndex:1 }}>
            <Proyectos/>
        </div>
    );
}

export default ProyectosP;
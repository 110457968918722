import imgBS1 from '../../../assets/images/projects/bombeo_solar_1.png';
import imgBS2 from '../../../assets/images/projects/bombeo_solar_2.png';
import imgBS3 from '../../../assets/images/projects/bombeo_solar_3.png';
import imgBS4 from '../../../assets/images/projects/bombeo_solar_4.png';
import imgBS5 from '../../../assets/images/projects/bombeo_solar_5.png';
import imgBS6 from '../../../assets/images/projects/bombeo_solar_6.png';
import imgBS7 from '../../../assets/images/projects/bombeo_solar_7.png';
import imgBS8 from '../../../assets/images/projects/bombeo_solar_8.png';
import imgBS9 from '../../../assets/images/projects/bombeo_solar_9.png';
import imgBS10 from '../../../assets/images/projects/bombeo_solar_10.png';
import imgBS11 from '../../../assets/images/projects/bombeo_solar_11.png';
import imgBS12 from '../../../assets/images/projects/bombeo_solar_12.png';
import imgBS13 from '../../../assets/images/projects/bombeo_solar_13.png';
import imgBS14 from '../../../assets/images/projects/bombeo_solar_14.png';

import imgC1 from '../../../assets/images/projects/comercio_1.png';
import imgC2 from '../../../assets/images/projects/comercio_2.png';
import imgC3 from '../../../assets/images/projects/comercio_3.png';
import imgC4 from '../../../assets/images/projects/comercio_4.png';
import imgC5 from '../../../assets/images/projects/comercio_5.png';
import imgC6 from '../../../assets/images/projects/comercio_6.png';
import imgC7 from '../../../assets/images/projects/comercio_7.png';
import imgC8 from '../../../assets/images/projects/comercio_8.png';
import imgC9 from '../../../assets/images/projects/comercio_9.png';
import imgC10 from '../../../assets/images/projects/comercio_10.png';
import imgC11 from '../../../assets/images/projects/comercio_11.png';
import imgC12 from '../../../assets/images/projects/comercio_12.png';
import imgC13 from '../../../assets/images/projects/comercio_13.png';
import imgC14 from '../../../assets/images/projects/comercio_14.png';
import imgC15 from '../../../assets/images/projects/comercio_15.png';
import imgC16 from '../../../assets/images/projects/comercio_16.png';
import imgC17 from '../../../assets/images/projects/comercio_17.png';
import imgC18 from '../../../assets/images/projects/comercio_18.png';
import imgC19 from '../../../assets/images/projects/comercio_19.png';
import imgC20 from '../../../assets/images/projects/comercio_20.png';


import imgI1 from '../../../assets/images/projects/industrial_1.png';
import imgI2 from '../../../assets/images/projects/industrial_2.png';
import imgI3 from '../../../assets/images/projects/industrial_3.png';
import imgI4 from '../../../assets/images/projects/industrial_4.png';
import imgI5 from '../../../assets/images/projects/industrial_5.png';
import imgI6 from '../../../assets/images/projects/industrial_6.png';
import imgI7 from '../../../assets/images/projects/industrial_7.png';
import imgI8 from '../../../assets/images/projects/industrial_8.png';
import imgI9 from '../../../assets/images/projects/industrial_9.png';
import imgI10 from '../../../assets/images/projects/industrial_10.png';
import imgI11 from '../../../assets/images/projects/industrial_11.png';
import imgI12 from '../../../assets/images/projects/industrial_12.png';
import imgI13 from '../../../assets/images/projects/industrial_13.png';
import imgI14 from '../../../assets/images/projects/industrial_14.png';
import imgI15 from '../../../assets/images/projects/industrial_15.png';
import imgI16 from '../../../assets/images/projects/industrial_16.png';
import imgI17 from '../../../assets/images/projects/industrial_17.png';
import imgI18 from '../../../assets/images/projects/industrial_18.png';

import imgR1 from '../../../assets/images/projects/residencial_1.png';
import imgR2 from '../../../assets/images/projects/residencial_2.png';
import imgR3 from '../../../assets/images/projects/residencial_3.png';
import imgR4 from '../../../assets/images/projects/residencial_4.png';
import imgR5 from '../../../assets/images/projects/residencial_5.png';
import imgR6 from '../../../assets/images/projects/residencial_6.png';
import imgR7 from '../../../assets/images/projects/residencial_7.png';
import imgR8 from '../../../assets/images/projects/residencial_8.png';
import imgR9 from '../../../assets/images/projects/residencial_9.png';
import imgR10 from '../../../assets/images/projects/residencial_10.png';
import imgR11 from '../../../assets/images/projects/residencial_11.png';
import imgR12 from '../../../assets/images/projects/residencial_12.png';
import imgR13 from '../../../assets/images/projects/residencial_13.png';
import imgR14 from '../../../assets/images/projects/residencial_14.png';
import imgR15 from '../../../assets/images/projects/residencial_15.png';
import imgR16 from '../../../assets/images/projects/residencial_16.png';
import imgR17 from '../../../assets/images/projects/residencial_17.png';
import imgR18 from '../../../assets/images/projects/residencial_18.png';


const arrayImgs = {
    bombeoS: [imgBS1, imgBS2, imgBS3, imgBS4, imgBS5, imgBS6, imgBS7, imgBS8, imgBS9, imgBS10, imgBS11, imgBS12, imgBS13, imgBS14],
    comercio: [imgC1, imgC2, imgC3, imgC4, imgC5, imgC6, imgC7, imgC8, imgC9, imgC10, imgC11, imgC12, imgC13, imgC14, imgC15, imgC16, imgC17, imgC18, imgC19, imgC20],
    industrial: [imgI1, imgI2, imgI3, imgI4, imgI5, imgI6, imgI7, imgI8, imgI9, imgI10, imgI11, imgI12, imgI13, imgI14, imgI15, imgI16, imgI17, imgI18],
    residencial: [imgR1, imgR2, imgR3, imgR4, imgR5, imgR6, imgR7, imgR8, imgR9, imgR10, imgR11, imgR12, imgR13, imgR14, imgR15, imgR16, imgR17, imgR18]
}


export default arrayImgs;
import React from "react";

//Components

import FormCotizar from "./FormCotizar";
import Sucursal from "./Sucursal";

const sucursales = [
    {
        title: 'Matriz',
        direction: 'Capilla de Guadalupe, Jalisco Carr. Tepa-Arandas “Rancho la Paleta” 27-A',
        phones: '(378) 7122636 / (378) 68 8 11 65',
        whatsapp: '378 885 38 50',
        center: {
            lat: 20.835093,
            lng: -102.604493
        }
    },
    {
        title: 'Sucursal Guadalajara',
        direction: 'Fundidor #5990, Artesanos, 45598 San Pedro Tlaquepaque, Jalisco',
        phones: '333 692 52 59',
        whatsapp: '',
        center: {
            lat: 20.579009,
            lng: -103.346788
        }
    },
    {
        title: 'Sucursal Aguascalientes',
        direction: 'Av. Aguascalientes Poniente #2617 Local 7, Colonia la España',
        phones: '449 377 32 14',
        whatsapp: '',
        center: {
            lat: 21.860371,
            lng: -102.316305
        }
    },
    {
        title: 'Sucursal León',
        direction: 'Paseo de Los Cóndores 202A, San Isidro, 37685 León, Gto',
        phones: '477 928 60 68 / 477 928 60 69',
        whatsapp: '',
        center: {
            lat: 21.099688,
            lng: -101.643842
        }
    },
]


const Contacto = () => {
    return (
        <div style={{ position: 'relative', zIndex: 1 }}>
            <FormCotizar />
            {
                sucursales.map((surcusal) => {
                    return <Sucursal {...surcusal} />
                })
            }

        </div>
    );
}

export default Contacto;